import Istaticks from "../component/HomeComponent/Istaticks";
import Acente from "../component/HomeComponent/Acente";
import Firms from "../component/HomeComponent/Firms";
import WhyAcente from "../component/HomeComponent/whyAcente";
import BusinessPartners from "../component/HomeComponent/BusinessPartners";
import HomeForm from "../component/HomeComponent/HomeForm";
import MobileApp from "../component/MobilUygulamaBand/mobilUygulama";
import HomeBlog from "../component/HomeBlog/homeBlog";
import HomeSlider from "../component/HomeSlider/homeSlider";
// import { useEffect, useState } from "react";
// import ApplicationPopup from "../component/ApplicationPopup/applicationpopup";
const Home = () => {
  // const [showPopup, setShowPopup] = useState(false);

  // useEffect(() => {
  //   const timeoutId = setTimeout(() => {
  //     setShowPopup(true);
  //   }, 10);
  //   return () => {
  //     clearTimeout(timeoutId);
  //   };
  // }, []);
  return (
    <div>
      <HomeSlider />
      <Istaticks showImage={true} />
      <MobileApp />
      <Acente />
      <Firms />
      <WhyAcente />
      <HomeBlog />
      <BusinessPartners numCardsToShow={3} />
      <HomeForm />
      {/* <div className={showPopup ? "asd" : ""}>
        <ApplicationPopup />
      </div> */}
    </div>
  );
};

export default Home;
