import React from "react";
import { Breadcrumb } from "antd";
import { useLocation } from 'react-router-dom';

const BreadCrump = ({ pageTitle, pageTitle2, pageTitle3,hrefLang,activeClassName }) => {
  const location = useLocation();
const currentPath=location.pathname;

  return (
    <div className="breadcrump">
      <div className="breadcrumps">
        <Breadcrumb separator=">">
          <Breadcrumb.Item href="/" className="pageTitle">
            {pageTitle}
          </Breadcrumb.Item>
          <Breadcrumb.Item href={hrefLang}className={activeClassName ? "pageTitle2":"pageTitle"}>{pageTitle2}</Breadcrumb.Item>
          {pageTitle3 &&  (
            <Breadcrumb.Item  className="pageTitle3">
              {pageTitle3}
            </Breadcrumb.Item>
          )}
        </Breadcrumb>
      </div>
    </div>
  );
};

export default BreadCrump;
