import nookies from "nookies"


export  function getSession(ctx = {}) {

    try {
        const cookies = nookies.get(ctx)
        const token = cookies.token
        const decodedToken = JSON.parse(token);
        return decodedToken
    } catch (e) {
        return null;
    }
}
export  default function getSessionSource(ctx = {}) {

    try {
        const cookies = nookies.get(ctx)
        const token = cookies.source;
        const decodedsource= JSON.parse(token);
        return decodedsource
    } catch (e) {
        return null;
    }
}
export async function getSessionAsync(ctx = {}) {
    return getSession(ctx)
}
