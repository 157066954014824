export const homedeteailData = [
  {
    icon: "img/icon/check-mark-circle.svg",
    description:
      "Müşteri ilişkilerini yönetebilir ve taleplerine hızlı cevap verebilirsiniz.",
  },
  {
    icon: "img/icon/check-mark-circle.svg",
    description:
      "Çapraz satış fırsatlarını kaybetmez, uyarı ekranlarıyla takiplerinizi yapabilirsiniz.",
  },
  {
    icon: "img/icon/check-mark-circle.svg",
    description:
      "Ekip gerçekten önemli. 20'den fazla uzman kadromuzla sürekli yanınızdayız.",
  },
  {
    icon: "img/icon/check-mark-circle.svg",
    description:
      "30'dan fazla sigorta şirketinden 2 dakikada teklif alırsınız.",
  },
  {
    icon: "img/icon/check-mark-circle.svg",
    description:
      "Güvenlik en hassas noktamız, en son teknolojiyle hizmet veririz.",
  },
  {
    icon: "img/icon/check-mark-circle.svg",
    description:
      "Müşteri ilişkilerini yönetebilir ve taleplerine hızlı cevap verebilirsiniz.",
  },
];
