import React, { useEffect, useState } from "react";
import BreadCrump from "../BreadCrump/breadcrump";
import CustomCollapse from "../Collapse/Collapse";
import HomeForm from "../HomeComponent/HomeForm";

const FrequentlyAskedQuestions = () => {
  const [isMobile, setIsMobile] = useState(false);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 1000);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="Questions">
      <div className="package-img">
        <img
          alt="banner"
          className="banner"
          src="img/banner/paket-banner.png"
        ></img>
      </div>
      <BreadCrump activeClassName={true} pageTitle="Sıkça Sorulan Sorular" />

      <div className="QuestionsContainer">
        <CustomCollapse
          title="Paketleri Nasıl Alıyoruz?"
          desctitle="Teknik Destek Süreci Nasıl İşliyor?"
        ></CustomCollapse>
        <CustomCollapse title="Teknik Destek Süreci Nasıl İşliyor?" />
        <CustomCollapse
          title="Teknik Destek Süreci Nasıl İşliyor?"
          desc="Sigorta sektörü, teknolojik gelişmelerle birlikte hızla dijitalleşmekte ve bu süreçte veri güvenliği önemli bir odak noktası haline gelmektedir. Bu bağlamda, Acente365 Sigorta Yazılımı, müşterilerin verilerini koruma ve güvenli bir şekilde işleme konusunda sağladığı çözümlerle dikkat çekmektedir.
          Sigorta Hızlı Teklif Yazılımları ve Veri GüvenliğiSigorta hızlı teklif yazılımları, müşterilere anında teklif sunma avantajı sağlarken aynı zamanda hassas kişisel ve finansal bilgileri işler. Bu nedenle"
        />
        <CustomCollapse title="Teknik Destek Süreci Nasıl İşliyor?" />
        <CustomCollapse title="Teknik Destek Süreci Nasıl İşliyor?" />
      </div>
      {isMobile === true ? <HomeForm /> : null}
    </div>
  );
};

export default FrequentlyAskedQuestions;
