import React from "react";

const HeaderTop = () => {
  return (
    <div className="header-top">
      <div className="header">
        <ul className="header-ul">
          <li>
            <div className="header-icon" style={{ display: "flex" }}>
              <img alt="mail" src="/img/icon/mail.svg"></img>
            </div>
            <a href="mailto:info@acente365.com.tr">info@acente365.com.tr</a>
          </li>
          <li>
            <div className="header-icon" style={{ display: "flex" }}>
              <img alt="phone" src="/img/icon/phone.svg"></img>
            </div>
            <a href="tel:0850 346 22 01">0850 346 22 01</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default HeaderTop;
