import React from "react";
import { notification } from "antd";

//  type   success / info / warning / error

const notificationMessage = (type, title, description) => {
  const information = titleAndColor(type);
  notification.destroy("1");
  notification[type || "success"]({
    message: (
      <span style={{ color: information.color, fontWeight: 500 }}>
        {title || information.title}
      </span>
    ),
    description: (
      <span style={{ fontSize: 16, fontWeight: 500 }}>
        {description || information.desc}
      </span>
    ),
    placement: "topRight",
    icon: information?.icon,
    key: "1",
    duration: 10,
  });
};

const titleAndColor = (type) => {
  switch (type) {
    case "info":
      return {
        // color: "#1890ff",
      };
    case "warning":
      return {
        // color: "#faad14",
      };
    case "error":
      return {
        // color: "#C00D13",
        icon: <img src="img/icon/remove-circle.svg" />,
        title: "Hata",
        desc: "",
      };

    default:
      return {
        // color: "#0F3567",
        icon: <img src="img/icon/check-circle.svg" />,
        title: "Başarılı",
        desc: "",
      };
  }
};

export default notificationMessage;
