import { BrowserRouter, Route, Routes } from "react-router-dom";
import Package from "./pages/package";
import Referances from "./pages/referanslar";
import News from "./pages/blog";
import Contact from "./pages/iletisim";
import Layout from "./component/Layout";
import Home from "./pages";
import Institutional from "./pages/institutional";
import FrequentlyAskedQuestions from "./component/Orientation/sikca-sorulan-sorular";
import Kurumsal from "./component/Kurumsal/Kurumsal";
import BlogDetail from "./pages/blog/blogDetail";
import PageNotFound from "./pages/404";

function App() {
  return (
    <>
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/institutional" element={<Institutional />} />
            <Route path="/paketlerimiz" element={<Package />} />
            <Route path="/referanslar" element={<Referances />} />
            <Route path="/blog" element={<News />} />
            <Route path="/iletisim" element={<Contact />} />
            <Route path="/blogdetail/:param1" element={<BlogDetail />}></Route>
            <Route path="/Kurumsal/:id" element={<Kurumsal />}></Route>
            <Route
              path="/sikca-sorulan-sorular"
              element={<FrequentlyAskedQuestions />}
            ></Route>
            <Route path="/Kurumsal" element={<Kurumsal />}></Route>
            <Route path="*" element={<PageNotFound />}></Route>
          </Routes>
        </Layout>
      </BrowserRouter>
    </>
  );
}

export default App;
