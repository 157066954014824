import React from "react";
import Bloga from "../../pages/blog/blog";
import { Link } from "react-router-dom";

const HomeBlog = () => {
  return (
    <div className="homeblog">
      <div className="homeblog-card">
        <div className="homeblog-desc">
          <span className="homeblog-title">Bizden Haberler</span>
          <span className="homeblog-description">
            Hem sigortacılık, hem sistemlerimiz hem de teknoloji dünyasına dair
            bilgi kaynağınız
          </span>
        </div>
        <div className="homeblog-detail">
          <Bloga isAll={false} form={false} />
        </div>
        <div className="homeblog-yonlendirme">
          <Link to="/blog">
            <span>Tüm haberleri gör</span>
          </Link>
          <img alt="direction-right" src="/img/icon/direction-right.svg"></img>
        </div>
      </div>
    </div>
  );
};

export default HomeBlog;
