import { Button, Form, Input } from "antd";
import React from "react";
const HomeForm = () => {
  return (
    <div className="homeform">
      <div className="form">
        <div className="forms">
          <div className="formsd">
            <div className="form-title">
              <span className="title">E-Bülten Aboneliği </span>
              <span className="desc">
                Gereksiz iletişime inanmıyoruz, ama Bizden Haberler için kayıt
                olabilirsiniz.
              </span>
            </div>
            <div className="form-input">
              <Form>
                <div className="form-input-style">
                  <Form.Item
                    name="nameSurnamew"
                    required={[
                      { required: true, message: "Bu alan boş bırakılamaz." },
                    ]}
                  >
                    <Input
                      className="form-input"
                      placeholder="E-Mail Adresiniz"
                    />
                  </Form.Item>
                  <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                    <Button
                      className="formsbutton"
                      type="#C00D13"
                      htmlType="submit"
                    >
                      Kayıt Ol
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </div>
          <div className="forms-image">
            <img
              style={{ width: "269px", height: "269px" }}
              src="img/vector/NewMail.png"
              alt="mail"
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeForm;
