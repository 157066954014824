import { Menu } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const HeaderWeb = () => {
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  const navigate = useNavigate();
  const handleClick = (linkto) => {
    navigate(linkto);
  };

  return (
    <div>
      <div className="navbar">
        <a className="logoimg" href="/">
          <img
            alt="Acente365Logo"
            src="/img/logo/logo.svg"
            className="img"
          ></img>
        </a>

        <Menu
          onClick={(item) => handleClick(item.key)}
          mode="inline"
          theme=""
          className="ulli"
        >
          <Menu.Item className="paddingleft" key="kurumsal">
            Kurumsal
          </Menu.Item>
          <Menu.Item className="paddinglef1" key="paketlerimiz">
            Paketlerimiz
          </Menu.Item>
          <Menu.Item className="paddingleft2" key="referanslar">
            Referanslarımız
          </Menu.Item>
          <Menu.Item className="paddingleft3" key="blog">
            Bizden Haberler
          </Menu.Item>
          <Menu.Item className="paddingleft4" key="iletisim">
            İletişim
          </Menu.Item>
        </Menu>

        <div
          style={{
            position: "fixed",
            top: 20,
            left: 20,
            cursor: "pointer",
            zIndex: 1,
          }}
          onClick={toggleCollapsed}
        ></div>
      </div>
    </div>
  );
};

export default HeaderWeb;
