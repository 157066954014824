import React, { useCallback, useEffect, useState } from "react";
import BreadCrump from "../BreadCrump/breadcrump";
import { Link, useLocation } from "react-router-dom";
import { Form, Select } from "antd";
import { Option } from "antd/es/mentions";
import { getStaticPage } from "../../services/statickPage";

const Kurumsal = () => {
  const [kurumsalData, setKurumsalData] = useState([
    {
      id: 1,
      name: "Acente 365",
      desc: "Borem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus.Curabitur tempor quis eros tempus lacinia.Nam bibendum pellentesque quam a convallis. Sed ut vulputate nisi. Integer in felis sed leo vestibulum venenatis. Suspendisse quis arcu sem. Aenean feugiat ex eu vestibulum vestibulum. Morbi a eleifend magna. Nam metus lacus, porttitor eu mauris a, blandit ultrices nibh. Mauris sit amet magna non ligula vestibulum eleifend. Nulla varius volutpat turpis sed lacinia. Nam eget mi in purus lobortis eleifend. Sed nec ante dictum sem condimentum ullamcorper quis venenatis nisi. Proin vitae facilisis nisi, ac posuere leo.Nam pulvinar blandit velit, id condimentum diam faucibus at. Aliquam lacus nisi, sollicitudin at nisi nec, fermentum congue felis. Quisque mauris dolor, fringilla sed tincidunt ac, finibus non odio. Sed vitae mauris nec ante pretium finibus. Donec nisl neque, pharetra ac elit eu, faucibus aliquam ligula. Nullam dictum, tellus tincidunt tempor laoreet, nibh elit sollicitudin felis, eget feugiat sapien diam nec nisl. Aenean gravida turpis nisi, consequat dictum risus dapibus a. Duis felis ante, varius in neque eu, tempor suscipit sem. Maecenas ullamcorper gravida sem sit amet cursus. Etiam pulvinar purus vitae justo pharetra consequat. Mauris id mi ut arcu feugiat maximus. Mauris consequat tellus id tempus aliquet.",
    },
    {
      id: 376,
      name: "Açık Rıza Metni",
      desc: "Borem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus.Curabitur tempor quis eros tempus lacinia.Nam bibendum pellentesque quam a convallis. Sed ut vulputate nisi. Integer in felis sed leo vestibulum venenatis. Suspendisse quis arcu sem. Aenean feugiat ex eu vestibulum vestibulum. Morbi a eleifend magna. Nam metus lacus, porttitor eu mauris a, blandit ultrices nibh. Mauris sit amet magna non ligula vestibulum eleifend. Nulla varius volutpat turpis sed lacinia. Nam eget mi in purus lobortis eleifend. Sed nec ante dictum sem condimentum ullamcorper quis venenatis nisi. Proin vitae facilisis nisi, ac posuere leo.Nam pulvinar blandit velit, id condimentum diam faucibus at. Aliquam lacus nisi, sollicitudin at nisi nec, fermentum congue felis. Quisque mauris dolor, fringilla sed tincidunt ac, finibus non odio. Sed vitae mauris nec ante pretium finibus. Donec nisl neque, pharetra ac elit eu, faucibus aliquam ligula. Nullam dictum, tellus tincidunt tempor laoreet, nibh elit sollicitudin felis, eget feugiat sapien diam nec nisl. Aenean gravida turpis nisi, consequat dictum risus dapibus a. Duis felis ante, varius in neque eu, tempor suscipit sem. Maecenas ullamcorper gravida sem sit amet cursus. Etiam pulvinar purus vitae justo pharetra consequat. Mauris id mi ut arcu feugiat maximus. Mauris consequat tellus id tempus aliquet.",
    },
    {
      id: 372,
      name: "Kişisel Verilerin İşlenmesine İlişkin Aydınlatma Metni",
      desc: "Borem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus.Curabitur tempor quis eros tempus lacinia.Nam bibendum pellentesque quam a convallis. Sed ut vulputate nisi. Integer in felis sed leo vestibulum venenatis. Suspendisse quis arcu sem. Aenean feugiat ex eu vestibulum vestibulum. Morbi a eleifend magna. Nam metus lacus, porttitor eu mauris a, blandit ultrices nibh. Mauris sit amet magna non ligula vestibulum eleifend. Nulla varius volutpat turpis sed lacinia. Nam eget mi in purus lobortis eleifend. Sed nec ante dictum sem condimentum ullamcorper quis venenatis nisi. Proin vitae facilisis nisi, ac posuere leo.Nam pulvinar blandit velit, id condimentum diam faucibus at. Aliquam lacus nisi, sollicitudin at nisi nec, fermentum congue felis. Quisque mauris dolor, fringilla sed tincidunt ac, finibus non odio. Sed vitae mauris nec ante pretium finibus. Donec nisl neque, pharetra ac elit eu, faucibus aliquam ligula. Nullam dictum, tellus tincidunt tempor laoreet, nibh elit sollicitudin felis, eget feugiat sapien diam nec nisl. Aenean gravida turpis nisi, consequat dictum risus dapibus a. Duis felis ante, varius in neque eu, tempor suscipit sem. Maecenas ullamcorper gravida sem sit amet cursus. Etiam pulvinar purus vitae justo pharetra consequat. Mauris id mi ut arcu feugiat maximus. Mauris consequat tellus id tempus aliquet.",
    },
    {
      id: 373,
      name: "KULLANICI ve GİZLİLİK SÖZLEŞMESİ",
      desc: "Borem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus.Curabitur tempor quis eros tempus lacinia.Nam bibendum pellentesque quam a convallis. Sed ut vulputate nisi. Integer in felis sed leo vestibulum venenatis. Suspendisse quis arcu sem. Aenean feugiat ex eu vestibulum vestibulum. Morbi a eleifend magna. Nam metus lacus, porttitor eu mauris a, blandit ultrices nibh. Mauris sit amet magna non ligula vestibulum eleifend. Nulla varius volutpat turpis sed lacinia. Nam eget mi in purus lobortis eleifend. Sed nec ante dictum sem condimentum ullamcorper quis venenatis nisi. Proin vitae facilisis nisi, ac posuere leo.Nam pulvinar blandit velit, id condimentum diam faucibus at. Aliquam lacus nisi, sollicitudin at nisi nec, fermentum congue felis. Quisque mauris dolor, fringilla sed tincidunt ac, finibus non odio. Sed vitae mauris nec ante pretium finibus. Donec nisl neque, pharetra ac elit eu, faucibus aliquam ligula. Nullam dictum, tellus tincidunt tempor laoreet, nibh elit sollicitudin felis, eget feugiat sapien diam nec nisl. Aenean gravida turpis nisi, consequat dictum risus dapibus a. Duis felis ante, varius in neque eu, tempor suscipit sem. Maecenas ullamcorper gravida sem sit amet cursus. Etiam pulvinar purus vitae justo pharetra consequat. Mauris id mi ut arcu feugiat maximus. Mauris consequat tellus id tempus aliquet.",
    },
    {
      id: 6,
      name: "Sertifikalarımız",
      desc: "Borem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus.Curabitur tempor quis eros tempus lacinia.Nam bibendum pellentesque quam a convallis. Sed ut vulputate nisi. Integer in felis sed leo vestibulum venenatis. Suspendisse quis arcu sem. Aenean feugiat ex eu vestibulum vestibulum. Morbi a eleifend magna. Nam metus lacus, porttitor eu mauris a, blandit ultrices nibh. Mauris sit amet magna non ligula vestibulum eleifend. Nulla varius volutpat turpis sed lacinia. Nam eget mi in purus lobortis eleifend. Sed nec ante dictum sem condimentum ullamcorper quis venenatis nisi. Proin vitae facilisis nisi, ac posuere leo.Nam pulvinar blandit velit, id condimentum diam faucibus at. Aliquam lacus nisi, sollicitudin at nisi nec, fermentum congue felis. Quisque mauris dolor, fringilla sed tincidunt ac, finibus non odio. Sed vitae mauris nec ante pretium finibus. Donec nisl neque, pharetra ac elit eu, faucibus aliquam ligula. Nullam dictum, tellus tincidunt tempor laoreet, nibh elit sollicitudin felis, eget feugiat sapien diam nec nisl. Aenean gravida turpis nisi, consequat dictum risus dapibus a. Duis felis ante, varius in neque eu, tempor suscipit sem. Maecenas ullamcorper gravida sem sit amet cursus. Etiam pulvinar purus vitae justo pharetra consequat. Mauris id mi ut arcu feugiat maximus. Mauris consequat tellus id tempus aliquet.",
    },
  ]);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idParam = searchParams.get("id");
  const [selectedLink, setSelectedLink] = useState(null);
  const [activeImg, setActiveImg] = useState(1);
  const [sozlesme, setSozlesme] = useState([]);
  const [selected, setSelected] = useState(sozlesme[0]);
  const [pageTitle, setPageTitle] = useState();
  const [sections, setSections] = useState(null);
  const sozlesmeController = useCallback(async () => {
    try {
      const response = await Promise.all([
        getStaticPage("acente365-nedir"),
        getStaticPage("kisisel-verilerin-korunmasi-hakkinda-aydinlatma-metni"),
        getStaticPage("kisisel-verilerin-islenmesine-iliskin-aydinlatma-metni"),
        getStaticPage("acik-riza-metni"),
      ]);

      const options = response.map((item) => ({
        id: item.id,
        metaTitle: item.metaTitle,
      }));

      setSozlesme(response);
      setSelected(response[0]);
      setActiveImg(response[0]?.id);
    } catch (error) {
      console.log("error", error);
    }
  }, []);
  useEffect(() => {
    sozlesmeController();
  }, [sozlesmeController]);

  //for selectbox
  const handleSelectChange = (value) => {
    const selectedData = sozlesme.find((e) => e?.id.toString() === value);
    setSelected(selectedData);
    setPageTitle(selectedData?.metaTitle);
  };
  //forSelectbox
  useEffect(() => {
    if (sozlesme.length > 0 && sections === null) {
      setSections(sozlesme[0].id.toString());
    }
  }, [sozlesme, sections]);;

  const findWhichInitilaze = (id) => {
    const intId = parseInt(id);

    const which = kurumsalData.find((e) => e.id === intId);

    if (which === undefined) {
      return null;
    }

    return which;
  };

  useEffect(() => {
    if (selectedLink === null) {
      setSelectedLink(kurumsalData[0]);
    }
  }, []);

  useEffect(() => {
    const value = findWhichInitilaze(idParam);

    if (value === null) {
    } else {
      setSelectedLink(value);
      setActiveImg(value?.id);
    }
  }, [idParam]);

  const onClickEvent = (id) => {
    const selectedData = sozlesme.find((e) => e?.id === id);
    setActiveImg(id);
    setPageTitle(selectedData?.postTitle);
    setSelected(selectedData);
  };

  return (
    <div className="kurumsal">
      <head>
        <title>Kurumsal</title>
        <meta charSet="UTF-8" />
        <meta name="description" content="Kurumsal" />
      </head>
      <div className="package-img">
        <img
          alt="banner"
          className="banner"
          src="img/banner/paket-banner.png"
        ></img>
      </div>
      <BreadCrump
        activeClassName={true}
        pageTitle="Anasayfa"
        pageTitle2="Kurumsal"
        pageTitle3={pageTitle}
        icon="img/icon/next.svg"
      />
      <div className="kurumsal-container">
        <div className="mobilboyut">
          <div className="kurumsal-value">
            <Form>
              <div className="formselectbox">
                <Form.Item
                  name="Konu Başlığı"
                  required={[
                    { required: true, message: "Bu alan boş bırakılamaz." },
                  ]}
                >
                  <Select
                    onChange={handleSelectChange}
                    className="selectkurumsal"
                    placeholder="Konu Başlığı"
                    value={sections}
                  >
                    {sozlesme.map((item) => (
                      <Option key={item.id} value={item.id.toString()}>
                        {item.postTitle}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </Form>
            <span
              className="page-desc2"
              dangerouslySetInnerHTML={{ __html: selected?.postContent }}
            ></span>
          </div>
        </div>
        <div className="webboyut">
          <div className="kurumsal-page">
            <div className="page">
              <div className="page-yon">
                {sozlesme?.map((item) => {
                  return (
                    <ListTileForLeftSide
                    id={item.id}
                      groupValue={activeImg}
                      title={item.postTitle}
                      onClick={onClickEvent}
                    ></ListTileForLeftSide>
                  );
                })}
              </div>
            </div>
            <div className="page-container">
              {
                <>
                  <div>
                    <h1 className="page-title">{selected?.metaTitle}</h1>
                    <span
                      className="page-desc"
                      dangerouslySetInnerHTML={{
                        __html: selected?.postContent,
                      }}
                    ></span>
                  </div>
                </>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function ListTileForLeftSide({ title, id, onClick, groupValue }) {
  return (
    <div className="istaksk">
      <div className="activeimg">
        {groupValue === id && (
          <img
            alt="Acente365"
            className="acentelogoimg"
            src="img/icon/acentelogo.png"
          ></img>
        )}
      </div>

      <div className="links ">
        <Link
          style={{ color: groupValue === id ? "#C00D13" : "#232323" }}
          onClick={() => onClick(id)}
        >
          {title}
        </Link>
      </div>
    </div>
  );
}

export default Kurumsal;
