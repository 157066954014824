import Httpio from "./Httpio";
export function getStaticPages(model) {
  return Httpio.CONTENT.get("/page/get-pages", {
    language: "tr-tr",
    ...model,
  });
}

export function getStaticPage(slug, model = {}) {
  return Httpio.CONTENT.get("/page/get-page", {
    language: "tr-tr",
    slug,
    ...model,
  });
}
