import React, { useCallback, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { getFirms } from "../../services/firmsService";
import { Autoplay } from "swiper/modules";
import "swiper/css/autoplay";

const Firms = () => {
  const [firmaImg, setFirmaImg] = useState([]);
  const getFirmsLogo = useCallback(async () => {
    try {
      const response = await getFirms();
      setFirmaImg(response);
    } catch (error) {
      console.log("Firma logoları bulunamadı", error);
    }
  }, []);

  useEffect(() => {
    getFirmsLogo();
  }, [getFirmsLogo]);

  return (
    <div className="Firms">
      <div className="firms-container">
        <img
          alt="rocket"
          className="rocketsvg"
          src="img/vector/Rocket.svg"
        ></img>
        <div className="firm-general">
          <div className="firm-general">
            <span className="firms-title">
              Sistemimizin Desteklediği Sigorta Firmaları
            </span>
            <span className="firms-description">
              Acente365 web sitesi ve mobil uygulamasıyla entegrasyonları
              tamamlanmış firmalar
            </span>
          </div>
        </div>
        <div className="business-card-container">
          <Swiper
            className="swiperslider"
            autoplay={{
              delay: 1,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            loop={true}
            speed={6000}
            centeredSlides={true}
            spaceBetween={10}
            slidesPerView={6}
            breakpoints={{
              767: {
                slidesPerView: 5,
                spaceBetween: 3,
              },
              844: {
                slidesPerView: 20,
              },
              1200: {
                slidesPerView: 5.5,
                spaceBetween: 10,
              },
            }}
          >
            {firmaImg?.map((item, index) => {
              return (
                <SwiperSlide key={index} className="SwiperSlideras" style={{}}>
                  <a href={item.urlAddress} className="firms-card">
                    <img
                      src={item.image}
                      className="sliderSwiperimg"
                      alt={item.firmTitle}
                    ></img>
                  </a>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Firms;
