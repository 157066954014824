import React, { useEffect, useState } from "react";
import BreadCrump from "../../component/BreadCrump/breadcrump";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getBlogDetail } from "../../services/blogService";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

const BlogDetail = () => {
  const [blogg, setBlogg] = useState({});
  const { param1 } = useParams();
  const [share, setShare] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  const shareUrl = `https://acente365.com.tr/${pathname}`;
  const handleShow = () => {
    setShare(!share);
  };

  const handleShare = (socialMedia) => {
    switch (socialMedia) {
      case "facebook":
        break;
      case "twitter":
        break;
      case "whatsapp":
        break;
      case "linkedln":
        break;
      default:
        break;
    }
    setShare(false);
  };

  function getBlogDetailPagination(param1) {
    getBlogDetail({
      id: param1,
    })
      .then((response) => {
        setBlogg(response);
      })
      .catch((error) => {
        console.log("Linke Ulaşılamadı", error);
      });
  }

  useEffect(() => {
    getBlogDetailPagination(param1);
  }, [param1]);

  return (
    <section className="blog-detail">
      <BreadCrump
        pageTitle="Anasayfa"
        pageTitle2="Bizden Haberler"
        hrefLang={"/blog"}
        pageTitle3={blogg.metaTitle}
        icon="img/icon/next.svg"
      />
      <div className="blog-detail-img">
        <img
          style={{ width: "100%", margin: "0 auto", objectFit:"cover"}}
          src={blogg.postImage}
          alt={blogg.metaTitle}
        ></img>
      </div>
      <div className="blog-detail-content">
        <div className="blog-detail-description">
          <h1 className="blog-detail-title">{blogg?.metaTitle}</h1>
          <p>{blogg.description}</p>
          <section
            style={{ listStyleType: "disc" }}
            dangerouslySetInnerHTML={{ __html: blogg.postContent }}
          ></section>
          <div style={{ width: "100%", display: "flex" }}>
            <div
              style={{
                width: "48px",
                height: "48px",
                borderRadius: "50%",
                background: "#EFEFEF",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <img
                style={{ width: "24px", height: "24px" }}
                src="/img/icon/share.svg"
                onClick={handleShow}
              ></img>
            </div>

            {share && (
              <div style={{ display: "flex" }}>
                <FacebookShareButton
                  url={shareUrl}
                  onClick={() => handleShare("facebook")}
                  style={{ marginLeft: "12px" }}
                >
                  <div
                    style={{
                      width: "48px",
                      height: "48px",
                      borderRadius: "50%",
                      background: "#EFEFEF",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <img src="/img/shareicon/fb.svg" alt="Facebook Icon"></img>
                  </div>
                </FacebookShareButton>

                <TwitterShareButton
                  url={shareUrl}
                  media={blogg.postThumbImage}
                  onClick={() => handleShare("twitter")}
                  style={{ marginLeft: "12px" }}
                >
                  <div
                    style={{
                      width: "48px",
                      height: "48px",
                      borderRadius: "50%",
                      background: "#EFEFEF",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      style={{ width: "24px", height: "24px" }}
                      src="/img/shareicon/x-platform-solid.svg"
                      alt="Twitter Icon"
                    ></img>
                  </div>
                </TwitterShareButton>

                <WhatsappShareButton
                  url={shareUrl}
                  onClick={() => handleShare("whatsapp")}
                  style={{ marginLeft: "12px" }}
                >
                  <div
                    style={{
                      width: "48px",
                      height: "48px",
                      borderRadius: "50%",
                      background: "#EFEFEF",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      style={{ width: "24px", height: "24px" }}
                      src="/img/shareicon/whatsapp.svg"
                      alt="WhatsApp Icon"
                    />
                  </div>
                </WhatsappShareButton>

                <LinkedinShareButton
                  url={shareUrl}
                  onClick={() => handleShare("linkedln")}
                  style={{ marginLeft: "12px" }}
                >
                  <div
                    style={{
                      width: "48px",
                      height: "48px",
                      borderRadius: "50%",
                      background: "#EFEFEF",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      src="/img/shareicon/linkedin.svg"
                      alt="WhatsApp Icon"
                    />
                  </div>
                </LinkedinShareButton>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogDetail;
