import { Button, Form, Input, Radio } from "antd";
import React, { useState } from "react";
import TenksOffer from "./TenksOffer";
import { getContact } from "../../services/contact";
import { isEmpty } from "lodash";
import notificationMessage from "../NotificationMessage/notificationMessage";
import InputMask from "react-input-mask";

const GetOffer = ({ onClose }) => {
  const [form] = Form.useForm();
  const [forwarded, setForwarded] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(false);

  const [payload, setPayload] = useState({
    Ad: "",
    FirmaName: "",
    Email: "",
    Telefon: "",
    Mesaj: "",
    SelectedPaket: "",
  });

  const sendContactData = async () => {
    const res = await getContact({
      MesajTipi: "iletişim",
      Ad: payload.Ad,
      Email: payload.Email,
      Telefon: payload.Telefon,
      Mesaj:
        `Firma:${payload.FirmaName}` +
        `Mesaj:${payload.Mesaj}` +
        `Seçili Paket:${payload.SelectedPaket}`,
    });
  };
  const handle = async () => {
    try {
      await form.validateFields();
      if (payload.Telefon.replace(/_/g, "").length < 12) {
        notificationMessage(
          "warning",
          "Telefon numarası en az 10 karakter içermelidir."
        );
        return;
      }
      if (!payload.SelectedPaket) {
        notificationMessage("warning", "Lütfen bir paket seçin.");
        return;
      }
      setForwarded(true);
      sendContactData();
    } catch (error) {}
  };

  const validateEmail = (rule, value, callback) => {
    const emailRegex = /^[A-Za-z0-9+_.-]+@(.+)$/;
    if (!emailRegex.test(value)) {
      callback("E-posta adresini doğru giriniz");
    } else {
      callback();
    }
  };
  const phoneRegex = /^\d{0,10}$/;
  return (
    <div className="getOfferPopup">
      <Form onFinish={handle} form={form}>
        {forwarded ? (
          <TenksOffer onClose={onClose} />
        ) : (
          <div className="offers">
            <div className="b">
              <img
                style={{ cursor: "pointer", width: "24px", height: "23px" }}
                src="img/icon/remove.svg"
                onClick={onClose}
                alt="remove"
              ></img>
            </div>
            <div className="getOffer">
              <div className="a">
                <span>Hızlı Teklif Alın</span>
              </div>
            </div>

            <div className="form-horizantal"></div>
            <div className="form">
              <Form.Item
                name="nameSurname"
                rules={[
                  {
                    required: true,
                    message: "Bu alan boş bırakılamaz.",
                  },
                  { min: 3, message: "En az 3 karakter girilmelidir." },
                  { max: 150, message: "En fazla 150 karakter girilebilir." },
                  {
                    pattern: /^[A-Za-z0-9\sÖöÇçĞğİıŞşÜü]+$/, 
                    message: "Özel karakterler kullanılamaz.",
                  },
                ]}
              >
                <Input
                  placeholder="Ad Soyad"
                  value={payload.Ad}
                  onChange={(e) => {
                    setPayload({ ...payload, Ad: e.target.value });
                  }}
                  maxLength={150}
                  minLength={3}
                />
              </Form.Item>
              <Form.Item
                name="firms"
                rules={[
                  {
                    required: true,
                    message: "Bu alan boş bırakılamaz.",
                  },
                  { min: 3, message: "En az 3 karakter girilmelidir." },
                  {
                    pattern: /^[A-Za-z0-9\sÖöÇçĞğİıŞşÜü]+$/, 
                    message: "Özel karakterler kullanılamaz.",
                  },
                ]}
              >
                <Input
                  value={payload.FirmaName}
                  onChange={(e) => {
                    setPayload({ ...payload, FirmaName: e.target.value });
                  }}
                  placeholder="Firma Adı"
                  maxLength={200}
                  minLength={3}
                />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[{ required: false }, { validator: validateEmail }]}
              >
                <Input
                  onChange={(e) => {
                    setPayload({ ...payload, Email: e.target.value });
                  }}
                  value={payload.Email}
                  placeholder="E-posta Adresi"
                  maxLength={200}
                  minLength={3}
                />
              </Form.Item>
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Bu alan boş bırakılamaz.",
                  },
                ]}
              >
                <InputMask
                  className="ant-form-item phone-item"
                  mask="999 999 9999"
                  maskChar="_"
                  value={payload.Telefon}
                  onChange={(e) => {
                    setPayload({ ...payload, Telefon: e.target.value });
                  }}
                  placeholder="Telefon Numarası"
                />
              </Form.Item>
              <span style={{ marginTop: "20px" }}>Paket Seçimi</span>
              <div style={{ display: "flex" }}>
                <Radio.Group
                  value={payload.SelectedPaket}
                  className="RadioGroupMobil"
                  onChange={(e) => {
                    setPayload({ ...payload, SelectedPaket: e.target.value });
                    setSelectedOptions(true);
                  }}
                  required
                >
                  <Radio
                    className="custom-radio"
                    style={{
                      transform: "scale(1.2)",
                    }}
                    onChange={(e) => {
                      setPayload({ ...payload, SelectedPaket: e.target.value });
                    }}
                    value={"Temel Paket"}
                  >
                    <span style={{ fontSize: "10px" }}>Temel Paket</span>
                  </Radio>
                  <Radio
                    className="custom-radio"
                    style={{ transform: "scale(1.2)" }}
                    value={"Standart Paket"}
                    onChange={(e) => {
                      setPayload({ ...payload, SelectedPaket: e.target.value });
                    }}
                  >
                    <span style={{ fontSize: "10px" }}>Standart Paket</span>
                  </Radio>
                  <Radio
                    className="custom-radio"
                    style={{ transform: "scale(1.2)" }}
                    value={"Profesyonel Paket"}
                    onChange={(e) => {
                      setPayload({ ...payload, SelectedPaket: e.target.value });
                    }}
                  >
                    <span style={{ fontSize: "10px", alignItems: "center" }}>
                      Profesyonel Paket
                    </span>
                  </Radio>
                </Radio.Group>
              </div>
              <div className="getoffersubmit">
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                  <Button
                    className="my-custom-button"
                    type="#C00D13"
                    onClick={handle}
                  >
                    Gönder
                  </Button>
                </Form.Item>
              </div>
            </div>
          </div>
        )}
      </Form>
    </div>
  );
};

export default GetOffer;
