import React, { useCallback, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/css";
import "swiper/css/navigation";
import { getSlider } from "../../services/slider";
import HomeSliderCard from "./homeSliderCard";
function NavigatorLinks() {
  return (
    <>
      <div className="mobileindirmelinks">
        <div className="mobileindirmeLinkssection">
          <a
            target="_blank"
            href="https://apps.apple.com/tr/app/acente365/id1589496090?l=tr"
            rel="noopener noreferrer"
          >
            <img alt="AppStore" src="img/app/appstore-light.svg"></img>
          </a>
          <a
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.megacode.acente365mobile"
            rel="noopener noreferrer"
          >
            <img alt="PlayStore" src="img/app/artwork.svg"></img>
          </a>
        </div>
      </div>
    </>
  );
}

const HomeSlider = () => {
  const [sliderData, setSliderData] = useState([]);
  const [active, setActive] = useState(false);
  const [imageActive, setImageActive] = useState();

  const sliderLocation = useCallback(async () => {
    try {
      const responseSlider = await getSlider("homepage");
      setSliderData(responseSlider);
      setImageActive(responseSlider[0]);
    } catch (error) {
      console.log("Görsel Bulunamadı Bulunamadı!", error);
    }
  }, []);

  useEffect(() => {
    sliderLocation();
  }, [sliderLocation]);
  const handleResize = () => {
    setActive(window.innerWidth <= 767);
  };
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="homeslider">
      <div className="home  sliders">
        <Swiper
          modules={[Navigation, Pagination,Autoplay]}
          spaceBetween={50}
          slidesPerView={1}
          autoplay={{
            delay:3000,
            disableOnInteraction:false,
          }}
          loop={true}
          pagination={active === true ? true : false}
          onSlideChange={(e) => setImageActive(sliderData[e.realIndex])}
          navigation={active === true ? false : true}
        >
          {sliderData?.map(
            (
              {
                SliderImagePath,
                MobileResponsiveSliderImagePath,
                MobileSliderImagePath,
                ShowSocialLink,
              },
              i
            ) => {
              return (
                <SwiperSlide>
                  <HomeSliderCard
                    key={i}
                    tabletImg={
                      MobileResponsiveSliderImagePath ||
                      MobileSliderImagePath ||
                      SliderImagePath
                    }
                    mobilImg={
                      MobileSliderImagePath ||
                      MobileResponsiveSliderImagePath ||
                      SliderImagePath
                    }
                    img={
                      SliderImagePath ||
                      MobileResponsiveSliderImagePath ||
                      MobileSliderImagePath
                    }
                    alt={ShowSocialLink}
                  />
                  <>
                    {imageActive?.Id === 161 && (
                      <>
                        <NavigatorLinks />
                      </>
                    )}
                  </>
                </SwiperSlide>
              );
            }
          )}
        </Swiper>
      </div>
    </div>
  );
};

export default HomeSlider;
