import React, { useCallback, useEffect, useState } from "react";

import { Link } from "react-router-dom";
import HomeForm from "../../component/HomeComponent/HomeForm";
import { getBlog } from "../../services/blogService";
import moment from "moment/moment";
import { Pagination } from "antd";

const Bloga = ({ isAll, pagination, form }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [blogg, setBlogg] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const pageSize = 6;

  const getBlogPagination = useCallback(async (cPage) => {
    try {
      const response = await getBlog({
        page: cPage,
        pageSize: pageSize,
        categorySlug: "blog",
      });
      setTotalPage(response.meta.total);
      setCurrentPage(cPage);
      setBlogg(response.data);
    } catch (error) {
      console.log("Blog bulunamadı!", error);
    }
  }, []);
  useEffect(() => {
    getBlogPagination();
  }, [getBlogPagination]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
    getBlogPagination(page);
  };
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 1000);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const howMany = isAll ? blogg.length : 3;
  return (
    <div className="institutional">
      <div className="blog-container">
        {blogg.slice(0, howMany).map((item, index) => {
          return (
            <Link
              id="RouterNavLink"
              key={index}
              to={`/blogdetail/${item.slug}`}
            >
              <div key={index} className="blog-card">
                <div className="blog-img">
                  <img alt={item.postTitle} src={item.postThumbImage}></img>
                </div>
                <div className="blog-desc">
                  <span className="blog-title">{item.postTitle}</span>
                  <span
                    className="blog-description"
                    dangerouslySetInnerHTML={{
                      __html:
                        item.postSummary.length > 140
                          ? item.postSummary.slice(0, 140) + "..."
                          : item.postSummary,
                    }}
                  ></span>
                </div>
                <div className="blog-detail">
                  <div className="blog-dates">
                    <img alt="date" src="img/icon/date.svg"></img>
                    <span>{moment(item.createdAt).fromNow()}</span>
                  </div>
                  <div className="blog-next">
                    <img
                      className="next-icon"
                      src="img/icon/eye.svg"
                      alt="icon"
                    ></img>
                    <span className="next">Devamını Oku</span>
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
      <div className="paginationcontainers">
        {pagination === true ? (
          <Pagination
            defaultCurrent={1}
            current={currentPage}
            onChange={handlePageChange}
            total={totalPage}
            pageSize={pageSize}
            itemRender={(current, type, originalElement) => {
              if (type === 'page' && current === currentPage) {
                return <div className="custom-active">{current}</div>;
              }
              return originalElement;
            }}
          />
        ) : null}
      </div>
      {isMobile && form === true ? <HomeForm /> : null}
    </div>
  );
};

export default Bloga;
