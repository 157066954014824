import ContactForm from "./ContactForm";
import ContactResponsive from "./contactResponsive";

const Contacts = () => {
  return (
    <div className="contact">
      <div className="contacts">
        <div className="contactsResponsive">
          <ContactResponsive />
          <ContactForm />
        </div>

        <div className="contactWeb">
          <div className="cont">
            <div className="cards">
              <div className="card">
                <div className="card-icerik">
                  <div className="card-title">
                    <span>Merkez</span>
                  </div>
                  <div className="card-desc">
                    <span>Turgut Özal Mahallesi</span>
                    <span>68. Sokak Otoport</span>
                    <span>No:46/222</span>
                    <span>Esenyurt - İstanbul</span>
                    <img
                      alt="location"
                      className=""
                      src="/img/vector/location.png"
                    ></img>
                  </div>
                  <div className="card-hor1"></div>
                  <div className="card-hor"></div>
                </div>
              </div>
              <div className="card">
                <div className="card-icerik">
                  <div className="card-title">
                    <span>Teknopark</span>
                  </div>
                  <div className="card-desc">
                    <span>Trakya Teknopark Ayşekadın</span>
                    <span>Yerleşkesi, Şükrü Paşa Mahallesi</span>
                    <span>Zübeyde Hanım Caddesi No:50</span>
                    <span>Merkez - Edirne</span>
                    <img
                      alt="location"
                      className=""
                      src="/img/vector/location.png"
                    ></img>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-icerik">
                  <div className="card-title">
                    <span>Mecidiyeköy</span>
                  </div>
                  <div className="card-desc">
                    <span>Mecidiyeköy Mahallesi Eski</span>
                    <span>Osmanlı Sokak Arıkan İş </span>
                    <span>Merkezi No:30</span>
                    <span>Şişli - İstanbul</span>
                    <img
                      alt="location"
                      className=""
                      src="/img/vector/location.png"
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="contacthori"></div>
          <ContactForm />
        </div>
      </div>
    </div>
  );
};

export default Contacts;
