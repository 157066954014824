import React from "react";
import { footerMenuData } from "../../utils/datas/footerMenuData";
import { Link } from "react-router-dom";
import { Collapse } from "antd";
const { Panel } = Collapse;
const FooterTop = () => {
  return (
    <>
      <div className="Footer">
        <div className="contentWrapper">
          {footerMenuData.map((menu, index) => {
            const { blanckActive, title, links } = menu;
            return (
              <article key={index} className="footerMenuData">
                <h5>{title}</h5>
                <div className="footerLinksContainer">
                  {links.map((link, index) => {
                    const { name, url } = link;
                    return (
                      <Link
                        target={blanckActive ? "_blank" : undefined}
                        key={index}
                        to={url}
                      >
                        <span className="link-text">{name}</span>
                      </Link>
                    );
                  })}
                </div>
                {/* Social media links mapping */}
                {index === footerMenuData.length - 1 && (
                  <div className="footersocial">
                    {footerMenuData[index].links[3].social.map(
                      (socialLink, index) => (
                        <a
                          key={index}
                          href={socialLink.href}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img alt={socialLink.alt} src={socialLink.src}></img>
                        </a>
                      )
                    )}
                  </div>
                )}
              </article>
            );
          })}

          <div className="footerMobileApp">
            <h5>Acente365 Mobil Uygulası Yayında!</h5>
            <a
              target="_blank"
              href="https://apps.apple.com/tr/app/acente365/id1589496090?l=tr"
              rel="noopener noreferrer"
            >
              <img alt="AppStore" src="img/app/appstore.svg" />
            </a>
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.megacode.acente365mobile"
              rel="noopener noreferrer"
            >
              <img alt="PlayStore" src="img/app/googleplay.svg" />
            </a>
          </div>
        </div>
        <div className="FooterBottom">
          <div className="footerBottom-container">
            <div className="footerBottom-logo">
              <a href="/">
                <img alt="Acente365" src="/img/logo/logo-2.svg"></img>
              </a>
            </div>
            <div className="footerBottom-acente">
              <span>
                Acente365 bir{" "}
                <a target="_blank" href="https://www.megacode.com.tr/">
                  Megacode
                </a>{" "}
                ürünüdür.
              </span>
              <span>Tüm Hakları Saklıdır.2023</span>
            </div>
          </div>
        </div>
        <div className="footermobile">
          <FooterMobil />
        </div>
      </div>
    </>
  );
};

function FooterMobil() {
  return (
    <div className="footerCollapse">
      <Collapse defaultActiveKey={["0"]} ghost expandIconPosition={"end"}>
        <div className="imglogo">
          <img alt="acente365" src="/img/logo/logo-yatay.svg"></img>
          <div className="border"></div>
        </div>
        {footerMenuData.map((menu, index) => {
          const { blanckActive, title, links } = menu;
          return (
            <Panel header={title} key={index}>
              <div className="footerLinksContainer">
                {links.map((link, index) => {
                  const { name, url } = link;
                  return (
                    <Link
                      target={blanckActive ? "_blank" : undefined}
                      key={index}
                      to={url}
                    >
                      <span className="link-text">{name}</span>
                    </Link>
                  );
                })}
              </div>
            </Panel>
          );
        })}
      </Collapse>

      <div className="footerSocialMedia2">
        <div className="border"></div>
        <div className="footerMobileApp">
          <h5>Acente365 Mobil Uygulası Yayında!</h5>
          <div className="footerMobileApp-links">
            <a
              target="_blank"
              href="https://apps.apple.com/tr/app/acente365/id1589496090?l=tr"
              rel="noopener noreferrer"
            >
              <img alt="AppStore" src="img/app/appstore.svg" />
            </a>
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.megacode.acente365mobile"
              rel="noopener noreferrer"
            >
              <img alt="PlayStore" src="img/app/googleplay.svg" />
            </a>
          </div>
        </div>
        <div className="footersocial">
          <a
            href="https://www.instagram.com/acente365.official"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img alt="Instgram" src="/img/socailMedia/instagram.svg"></img>
          </a>
          <a
            href="https://www.linkedin.com/company/megacode/mycompany/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img alt="Linkedin" src="/img/socailMedia/linkedin.svg"></img>
          </a>
          <a
            href="https://www.facebook.com/megacodetech/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img alt="Facebook" src="/img/socailMedia/fb.svg"></img>
          </a>
          <a
            href="https://twitter.com/megacodeyazilim"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              alt="Twitter"
              src="/img/socailMedia/x-platform-solid.svg"
            ></img>
          </a>
        </div>
        <div className="footerBottom-acente-responsive">
          <span>
            Acente365 bir{" "}
            <a target="_blank" href="https://www.megacode.com.tr/">
              Megacode
            </a>{" "}
            ürünüdür.
          </span>
          <span>Tüm Hakları Saklıdır.2023</span>
        </div>
      </div>
    </div>
  );
}

export default FooterTop;
