import React from "react";
import Navbar from "./Header/header";
import FooterTop from "./Footer/footerTop";
import HeaderTop from "./Header/headerTop";

const Layout = ({ children }) => {
  return (
    <>
      <HeaderTop />
      <div className="LayoutMain">
        <Navbar />
        <div className="line-bg">
          <main>{children}</main>
        </div>

        <FooterTop />
        <div className="fixed-whatsapp">
          <a
            target="_blank"
            href="https://wa.me/905333874408"
            rel="noopener noreferrer"
          >
            <img alt="Acente365 Whatsapp" src="/img/icon/whatsapp.png" />
          </a>
        </div>
      </div>
    </>
  );
};

export default Layout;
