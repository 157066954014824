import HeaderWeb from "./HeaderWeb";
import HeaderMobil from "./HeaderMobil";

const Navbar = () => {
  return (
    <div>
      <div className="mobilboyut">
        <HeaderMobil />
      </div>
      <div className="webboyut">
        <HeaderWeb />
      </div>
    </div>
  );
};

export default Navbar;
