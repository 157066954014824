import React from "react";

const Markalar = () => {
  return (
    <div className="Brands">
      <div className="Brands-Container">
        <div className="Brands-title">
          <span className="title">Referanslarımız</span>
          <span className="branddesc">
            İş ortaklarımızın memnuniyeti en iyi referansımızdır. Siz de aramıza
            katılın, 7/24 desteğimizle fark yaratın.
          </span>
        </div>
        <div className="Brands-img">
          <div className="brands-img1">
            <div className="brands-card">
              <img src="img/referanslar/dijipol-logo.png" alt="Dijipol"></img>
            </div>
            <div className="brands-card">
              <img src="img/referanslar/ozserneo.png" alt="ÖzserNeo"></img>
            </div>
            <div className="brands-card">
              <img
                src="img/referanslar/sigortamobil.png"
                alt="Sigortamobil"
              ></img>
            </div>
            <div className="brands-card">
              <img src="img/referanslar/tebom.png" alt="Tebom"></img>
            </div>
          </div>
          <div className="brands-img2">
            <div className="brands-card">
              <img src="img/referanslar/karturk.png" alt="Kartürk"></img>
            </div>
            <div className="brands-card">
              <img src="img/referanslar/sigobi.png" alt="Sigobi"></img>
            </div>
            <div className="brands-card">
              <img
                src="img/referanslar/sigortamgetir.png"
                alt="Sigortamgetir"
              ></img>
            </div>
            <div className="brands-card">
              <img src="img/referanslar/sigortabox.png" alt="Sigortabox"></img>
            </div>
          </div>
          <div className="brands-img3">
            <div className="brands-card">
              <img src="img/referanslar/tamoniki.png" alt="Tamoniki"></img>
            </div>
            <div className="brands-card">
              <img src="img/referanslar/NecworkLogo.svg" alt="Necwork"></img>
            </div>
            <div className="brands-card">
              <img src="img/referanslar/sigortafoni.svg" alt="Sigortafoni"></img>
            </div>
          </div>
        </div>
      </div>
      <div className="referanceshorizantel">
        <img alt="vector" className="horizantell" src="img/vector/1.svg"></img>
      </div>
    </div>
  );
};

export default Markalar;
