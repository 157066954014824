import React from "react";
import Kurumsal from "../component/Kurumsal/Kurumsal";

const Institutional = () => {
  return ( 
    <>
      <Kurumsal />
    </>
  );
};

export default Institutional;
