import React from "react";
import Bloga from "./blog/blog";
import BreadCrump from "../component/BreadCrump/breadcrump";

const News = () => {
  return (
    <>
      <div className="blogContainer">
        <head>
          <title>Bizden Haberler</title>
          <meta charSet="UTF-8" />
          <meta name="description" content="Bizden Haberler" />
        </head>
        <div className="institutional-img">
          <img
            alt="banner"
            className="banner"
            src="img/banner/banner.png"
          ></img>
        </div>
        <BreadCrump activeClassName={true} pageTitle="Anasayfa" pageTitle2="Bizden Haberler" />
        <Bloga isAll={true} pagination={true} />
      </div>
    </>
  );
};

export default News;
