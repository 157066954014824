import { Drawer, Menu } from "antd";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const HeaderMobil = () => {
  const [mobilActive, setMobileActive] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const handle = (linkto) => {
    navigate(linkto);
  };
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  const handleClick = () => {
    setMobileActive(!mobilActive);
  };
  const handleMenuClick = () => {
    setMobileActive(false);
  };

  return (
    <div>
      <div className="headermobil">
        <Link to={"/"}>
          <img
            alt="Acente365Logo"
            className="logoimg"
            src="/img/logo/logo.svg"
          ></img>
        </Link>
        <img
          alt="hamburgerMenu"
          onClick={handleClick}
          src="/img/icon/hamburger-outline.svg"
        ></img>

        <Drawer
          onClick={handleMenuClick}
          onClose={handleClick}
          width={"100%"}
          open={mobilActive}
        >
          <div className="menuitem">
            <Menu onClick={(item) => handle(item.key)} mode="vertical" theme="">
              <Menu.Item key="/kurumsal">Kurumsal</Menu.Item>
              <Menu.Item key="paketlerimiz">Paketlerimiz</Menu.Item>
              <Menu.Item key="referanslar">Referanslarımız</Menu.Item>
              <Menu.Item key="blog">Bizden Haberler</Menu.Item>
              <Menu.Item key="iletisim">İletişim</Menu.Item>
            </Menu>

            <div
              style={{
                position: "fixed",
                top: 20,
                left: 20,
                cursor: "pointer",
                zIndex: 1,
              }}
              onClick={toggleCollapsed}
            ></div>
          </div>
        </Drawer>
      </div>
    </div>
  );
};

export default HeaderMobil;
