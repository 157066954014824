import Httpio from "../services/Httpio";

export function getBlog(model) {
  return Httpio.CONTENT.get("/blog/get-blogposts", {
    language: "tr-tr",
    fromPlace: 25,
    ...model,
  });
}

export function getBlogDetail(model) {
  return Httpio.CONTENT.get("/blog/get-blogpost", {
    language: "tr-tr",
    fromPlace: 25,

    ...model,
  });
}
