import React from "react";
import BreadCrump from "../component/BreadCrump/breadcrump";
import Markalar from "../component/References/Markalar";
import Istaticks from "../component/HomeComponent/Istaticks";

const Referances = () => {
  return (
    <>
      <div className="References">
        <head>
          <title>Referanslar</title>
          <meta charSet="UTF-8" />
          <meta name="description" content="Referanslar" />
        </head>
        <div className="package-img">
          <img
            alt="banner"
            className="banner"
            src="img/banner/banner.png"
          ></img>
        </div>
        <BreadCrump activeClassName={true} pageTitle="Anasayfa" pageTitle2="Referanslarımız" />
        <Markalar />
        <div style={{ marginBottom: "60px" }}>
          <Istaticks showImage={false} />
        </div>
      </div>
    </>
  );
};

export default Referances;
