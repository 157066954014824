import React from "react";

const MobileApp = () => {
  return (
    <div className="mobileapp">
      <div className="mobileapp-desc">
        <div className="titleacente">
          <span>Acente365 Mobil Uygulamasını Hemen İndirin</span>
        </div>
        <div className="linksmobile">
          <a
            target="_blank"
            href="https://apps.apple.com/tr/app/acente365/id1589496090?l=tr"
            rel="noopener noreferrer"
          >
            <img alt="AppStore" src="img/app/appstore-light.svg"></img>
          </a>
          <a
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.megacode.acente365mobile"
            rel="noopener noreferrer"
          >
            <img
              rel="noopener noreferrer"
              alt="PlayStore"
              src="img/app/artwork.svg"
            ></img>
          </a>
        </div>
      </div>
    </div>
  );
};

export default MobileApp;
