import { Form, Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useState } from "react";
import { getContact } from "../../services/contact";
import { isEmpty } from "lodash";
import notificationMessage from "../NotificationMessage/notificationMessage";

const ContactForm = () => {
  const [payload, setPayload] = useState({
    Ad: "",
    FirmaName: "",
    Email: "",
    Mesaj: "",
  });

  const sendContactData = async () => {
    const ayrac = " - ";
    const res = await getContact({
      MesajTipi: "iletişim",
      Ad: payload.Ad,
      Email: payload.Email,
      Mesaj: `Firma:${payload.FirmaName}${ayrac}Mesaj:${payload.Mesaj}`,
    });
  };

  const handle = () => {
    if (
      !isEmpty(payload.Ad) &&
      !isEmpty(payload.FirmaName) &&
      !isEmpty(payload.Email) &&
      !isEmpty(payload.Mesaj)
    ) {
      notificationMessage(
        "success",
        "Mesajınız bize ulaştı.En kısa zamanda sizinle iletişime geçeceğiz."
      );
      sendContactData();
    } else {
      notificationMessage("error", "Lütfen tüm zorunlu alanları doldurunuz");
    }
  };
  const validateEmail = (rule, value, callback) => {
    const emailRegex = /^[A-Za-z0-9+_.-]+@(.+)$/;
    if (!emailRegex.test(value)) {
      callback("E-posta adresini doğru giriniz");
    } else {
      callback();
    }
  };

  return (
    <Form name="myForm" method="post" onFinish={handle}>
      <div style={{ clear: "right" }} className="form">
        <Form.Item
          name="nameSurname"
          rules={[{ required: true, message: "Bu alan boş bırakılamaz." }]}
        >
          <Input
            value={payload.Ad}
            className="formInput"
            style={{ width: "600px !important" }}
            placeholder="Ad Soyad"
            onChange={(e) => {
              setPayload({ ...payload, Ad: e.target.value });
            }}
          />
        </Form.Item>
        <Form.Item
          name="Firms"
          rules={[{ required: true, message: "Bu alan boş bırakılamaz." }]}
        >
          <Input
            value={payload.FirmaName}
            className="formInput"
            placeholder="Firma Adı"
            onChange={(e) => {
              setPayload({ ...payload, FirmaName: e.target.value });
            }}
          />
        </Form.Item>
        <Form.Item
          name="Mail"
          rules={[{ required: false }, { validator: validateEmail }]}
        >
          <Input
            value={payload.Email}
            className="formInput"
            placeholder="E-posta Adresi"
            onChange={(e) => {
              setPayload({ ...payload, Email: e.target.value });
            }}
          />
        </Form.Item>
        <Form.Item
          name="textArea"
          rules={[{ required: true, message: "Bu alan boş bırakılamaz." }]}
        >
          <TextArea
            className="formInput"
            autoSize={{ minRows: 6, maxRows: 10 }}
            value={payload.Mesaj}
            placeholder="Mesajınız"
            onChange={(e) => {
              setPayload({ ...payload, Mesaj: e.target.value });
            }}
          />
        </Form.Item>

        <button type="submit" className="btn-form">
          Gönder
        </button>
      </div>
    </Form>
  );
};

export default ContactForm;
