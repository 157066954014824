import Httpio from "./Httpio";

export function getFirms(BranchId) {
    return Httpio.CONTENT.get("/firms/GetFirms", {
      language: "tr-tr",
      BranchId,
    });
  }
  
  export function getBranches(model) {
    return Httpio.CONTENT.get("/Branch/GetBranches", {
      language: "tr-tr",
      ...model,
    });
  }