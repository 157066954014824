const TenksOffer = ({ onClose }) => {
  return (
    <div className="ThanksForm">
      <div className="ThanksForm-container">
        <div className="b">
          <img
            style={{ cursor: "pointer", width: "24px", height: "23px" }}
            src="img/icon/remove.svg"
            onClick={onClose}
            alt="remove"
          ></img>
        </div>
        <div className="ThanksOfferTitle">
          <div className="a">
            <span>Hızlı Teklif Alın</span>
          </div>
        </div>
        <div className="form-horizantal"></div>
        <div className="img">
          <img
            style={{ width: "200px", height: "200px" }}
            src="img/vector/mail.png"
            alt="mail"
          ></img>
        </div>
        <div className="Thanks-desc">
          <span className="desc-title">Mesajınız iletilmiştir.</span>
          <span className="desc-title">
            Satış ekibimiz en kısa zamanda sizinle iletişime geçecek.
          </span>
        </div>
      </div>
    </div>
  );
};

export default TenksOffer;
