import getSession from "../auth/getSession";
import getSessionSource from "../auth/getSessionSource";

let _context = null;

export function getToken() {
  const getToken = getSession(_context);
  return getToken?.token;
}
export function getSource() {
  const getSource = getSessionSource(_context);
  return getSource?.source;
}

export function setContext(ctx) {
  _context = ctx;
}
