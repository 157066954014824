import React from "react";

const ContactResponsive = () => {
  return (
    <div className="contactResponsive">
      <div className="contact-responsivegeneral">
        <div className="contact-responsiveimg">
          <img alt="call" src="/img/icon/call.svg"></img>
        </div>
        <span className="responsivetelephone">0850 346 22 01</span>
      </div>
      <div className="contactresponsivecard">
        <div className="contactcity">
          <span className="title">Merkez</span>
          <span className="contactadress">
            Turgut Özal Mahallesi 68. Sokak Otoport No:46/222 Esenyurt -
            İstanbul
          </span>
        </div>
        <div  className="contactcity">
          <span className="title">Teknopark</span>
          <span className="contactadress">
            Trakya Teknopark Ayşekadın Yerleşkesi, Şükrü Paşa Mahallesi Zübeyde
            Hanım Caddesi No:50 Merkez - Edirne
          </span>
        </div>
        <div  className="contactcity">
          <span className="title">Mecidiyeköy</span>
          <span className="contactadress">
            Mecidiyeköy Mahallesi Eski Osmanlı Sokak Arıkan İş Merkezi No:30
            Şişli - İstanbul
          </span>
        </div>
      </div>
    </div>
  );
};

export default ContactResponsive;
