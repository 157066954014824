import { Button, Form } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import GetOffer from "../GetOffer/GetOffer";

const PackageMobil = () => {
  return (
    <div>
      <div className="packageMobil">
        <div className="packageMobil-container">
          <PackageListTile
            title={"Temel Paket"}
            img={"img/packageMobil/Cloud.png"}
          ></PackageListTile>

          <PackageListTile
            title={"Standart Paket"}
            img={"img/packageMobil/PieChart.png"}
            isMost={true}
          ></PackageListTile>

          <PackageListTile
            title={"Profesyonel Paket"}
            img={"img/packageMobil/rocket.png"}
          ></PackageListTile>
        </div>
      </div>
    </div>
  );
};

function PackageListTile({ description, title, isMost, img }) {
  const [isOpen, setIsPoen] = useState(false);
  return (
    <>
      <div
        onClick={() => {
          setIsPoen(!isOpen);
        }}
        className="packageMobils"
      >
        {isMost === true ? <div className="PopulerPackage">POPÜLER</div> : null}

        <div className="packageMobilsSection">
          <div className="packageMobils-oge">
            <img alt="icon" src={img}></img>
          </div>
          <div className="packageMobils-oge1">
            <span>{title}</span>
          </div>
          <div className="packageMobils-oge2">
            <Link>
              <span>Paket Detayları</span>
              {isOpen === true ? (
                <img
                  style={{ transform: "rotate(90deg)" }}
                  src="img/icon/next.svg"
                  alt="icon"
                />
              ) : (
                <img alt="icon" src="img/icon/next.svg" />
              )}
            </Link>
          </div>
        </div>
      </div>
      {isOpen ? <PackageDetails pageTitle={title} /> : null}
    </>
  );
}

function PackageDetails({ pageTitle }) {
  const descriptionData = [
    {
      id: 1,
      description: "Online Teklif Alma",
      description2: "Online Poliçe Yapılandırma",
      description3: "Online Zeyil",
      description4: "Otomatik Yenileme",
      description5: "Yardım / Destek Modülü",
      description6: "Şube Modülü",
      description7: "Sorgulama Servis Modülü",
      description8: "Günlük Yedekleme Hizmeti",
      description9: "2FA Güvenli Giriş İmkanı",
    },
    {
      id: 2,
      description: "Online Teklif Alma",
      description2: "Online Poliçe Yapılandırma",
      description3: "Online Zeyil",
      description4: "Otomatik Yenileme",
      description5: "Yardım / Destek Modülü",
      description6: "Şube Modülü",
      description7: "Sorgulama Servis Modülü",
      description8: "Günlük Yedekleme Hizmeti",
      description9: "2FA Güvenli Giriş İmkanı",
      description10: "İnsan Kaynakları Modülü",
      description11: "Hedef Pazarlama Modülü",
      description12: "Duyuru Yönetim Modülü",
      description13: "Portföy Yönetim Modülü",
    },
    {
      id: 3,
      description: "Online Teklif Alma",
      description2: "Online Poliçe Yapılandırma",
      description3: "Online Zeyil",
      description4: "Otomatik Yenileme",
      description5: "Yardım / Destek Modülü",
      description6: "Şube Modülü",
      description7: "Sorgulama Servis Modülü",
      description8: "Günlük Yedekleme Hizmeti",
      description9: "2FA Güvenli Giriş İmkanı",
      description10: "İnsan Kaynakları Modülü",
      description11: "Hedef Pazarlama Modülü",
      description12: "Duyuru Yönetim Modülü",
      description13: "Portföy Yönetim Modülü",
      description14: "Müşteri Memnuniyeti Yönetimi",
      description15: "Çapraz Satış",
      description16: "Otomatik Poliçe Aktarma",
      description17: "Online Kasko Değeri Sorgulama",
      description18: "Online Teklif ve Poliçe Entegrasyonu",
    },
  ];

  const filterData = descriptionData.filter(
    (item) =>
      (item.id === 1 && pageTitle === "Temel Paket") ||
      (item.id === 2 && pageTitle === "Standart Paket") ||
      (item.id === 3 && pageTitle === "Profesyonel Paket")
  );
  const [isPopup, setIsPopup] = useState(false);

  const handleButtonClick = () => {
    setIsPopup(true);
  };
  const closePopup = () => {
    setIsPopup(false);
  };

  return (
    <Form>
      <div className="packageMobilDetail">
        <div
          style={{
            border: "1px solid #C00D13",
            width: "383px",
            margin: "0 auto",
          }}
          className="border"
        ></div>
        <div className="packageMobilDetail-container">
          <ul
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            {filterData.map((item) => {
              return (
                <>
                  <li>{item.description}</li>
                  <li>{item.description2}</li>
                  <li>{item.description3}</li>
                  <li>{item.description4}</li>
                  <li>{item.description5}</li>
                  <li>{item.description6}</li>
                  <li>{item.description7}</li>
                  <li>{item.description8}</li>
                  <li>{item.description9}</li>
                  <li>{item.description10}</li>
                  <li>{item.description11}</li>
                  <li>{item.description12}</li>
                  <li>{item.description13}</li>
                  <li>{item.description14}</li>
                  <li>{item.description15}</li>
                  <li>{item.description16}</li>
                  <li>{item.description17}</li>
                  <li>{item.description18}</li>
                </>
              );
            })}
          </ul>
          <div style={{ width: "338px" }} className="btn">
            <Form.Item>
              <Button
                className="packagebtn"
                type="#C00D13"
                htmlType="submit"
                onClick={() => handleButtonClick()}
              >
                Hızlı teklif alın
              </Button>
            </Form.Item>
          </div>
          {isPopup && <GetOffer onClose={closePopup} />}
        </div>
      </div>
    </Form>
  );
}

export default PackageMobil;
