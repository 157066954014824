import React, { useMemo } from "react";
import { useWindowSize } from "../../shared/hook/useWindowSize";

const HomeSliderCard = ({ tabletImg, mobilImg, img, alt }) => {
  const { width } = useWindowSize();

  const imgController = useMemo(() => {
    if (1200 >= width && 768 < width) {
      return tabletImg;
    } else if (768 >= width) {
      return mobilImg;
    } else {
      return img;
    }
  }, [img, mobilImg, tabletImg, width]);
  return (
    <>
      {width && (
        <img
          className="SliderImage"
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
          }}
          src={imgController}
          alt={alt}
        ></img>
      )}
    </>
  );
};

export default HomeSliderCard;
