import React from "react";

const PageNotFound = () => {
  return (
    <div className="pagenotfound">
      <div className="pagenotfound-container">
        <div className="pagenotfound-description">
          <h1>Opps</h1>
          <h2>Aradığın sayfayı bulamadık.</h2>
          <h2>
            Erişmeye çalıştığın sayfa çıkartılmış veya içeriği düzenleniyor
            olabilir.
          </h2>
          <img src="img/404/404-image.svg"></img>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
