import React from "react";
import { homedeteailData } from "../../utils/datas/homedeteailData";

const WhyAcente = () => {
  return (
    <div className="acente-details">
      <div className="acente365">
        <div className="acente-detail-description">
          <span className="whys">
            Peki Neden<span className="acentewhys"> Acente365?</span>
          </span>
          <span className="acentewhyss">
            Ekibimizle tanıştıktan sonra daha iyi anlayacaksınız, ama bir kaç
            maddeyle belirtelim;
          </span>
        </div>
        <div className="detail-photo">
          <div className="detail-photos">
            <span>Öğrenmesi Kolay</span>
            <img
              alt="Öğrenmesi Kolay"
              className="vectorimg"
              src="img/vector/13.png"
            ></img>
          </div>
          <div className="detail-photos">
            <span>Her Noktadan Erişim</span>
            <img
              alt="Her Noktadan Erişim"
              className="vectorimg"
              src="img/vector/14.png"
            ></img>
          </div>
          <div className="detail-photos">
            <span>3.000+ Kullanıcı</span>
            <img
              alt="3.000+ Kullanıcı"
              className="vectorimg"
              src="img/vector/15.png"
            ></img>
          </div>
          <div className="detail-photos">
            <span>Uzman Ekip Desteği</span>
            <img
              alt="Uzman Ekip Desteği"
              className="vectorimg"
              src="img/vector/16.png"
            ></img>
          </div>
        </div>
        <div className="details">
          <div className="details-ic">
            {homedeteailData.map((item, index) => {
              return (
                <div className="ic" key={index}>
                  <img alt="icon" src={item.icon}></img>
                  <span>{item.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyAcente;
