import React, { useState } from "react";
import BreadCrump from "../component/BreadCrump/breadcrump";
import GetOffer from "../component/GetOffer/GetOffer";
import { Button, Form } from "antd";
import PackageMobil from "../component/Package/packageMobil";

const Package = () => {
  const [isPopup, setIsPopup] = useState(false);

  const handleButtonClick = () => {
    setIsPopup(true);
  };
  const closePopup = () => {
    setIsPopup(false);
  };

  return (
    <>
      
      <div className="package-container">
      <head>
        <title>Paketler</title>
        <meta charSet="UTF-8" />
        <meta name="description" content="Paketler" />
      </head>
        <div className="package-img">
          <img
            alt="banner"
            className="banner"
            src="img/banner/paket-banner.png"
          ></img>
        </div>
        <BreadCrump activeClassName={true} pageTitle="Anasayfa" pageTitle2="Paketlerimiz" />
        <div className="mobilboyut">
          <PackageMobil />
        </div>
        <div className="webboyut">
          <Form>
            <div className="package">
              <div className="packages">
                <div className="packages-radius">
                  <div className="packages-img">
                    <img
                      className="settingsimg"
                      style={{ width: "300px", height: "300px" }}
                      src="img/Settings.png"
                      alt="settings"
                    ></img>
                    <img alt="Cloud" src="img/vector/Cloud.png"></img>
                  </div>
                  <div
                    style={{
                      background: "#F5ECC8",
                    }}
                    className="package-oge"
                  >
                    <span className="packages-title">TEMEL PAKET</span>
                    <div className="packages-list">
                      <span>Online Teklif Alma</span>
                      <span>Online Poliçe Yapılandırma</span>
                      <span>Online Zeyil</span>
                      <span>Otomatik Yenileme</span>
                      <span>Yardım / Destek Modülü</span>
                      <span>Şube Modülü</span>
                      <span>Sorgulama Servis Modülü</span>
                      <span>Günlük Yedekleme Hizmeti</span>
                      <span>2FA Güvenli Giriş İmkanı</span>
                    </div>
                  </div>
                  <div style={{ width: "239px" }} className="btn">
                    <Form.Item>
                      <Button
                        className="packagebtn"
                        type="#C00D13"
                        htmlType="submit"
                        onClick={() => handleButtonClick()}
                      >
                        Hızlı teklif alın
                      </Button>
                    </Form.Item>
                  </div>
                </div>
                <div className="packages-radius1">
                  <div className="packages-img">
                    <img alt="PieChart" src="img/vector/PieChart.png"></img>
                  </div>
                  <div className="populer">POPÜLER</div>
                  <div
                    style={{
                      background: "#D0E8F7",
                    }}
                    className="package-oge1"
                  >
                    <span className="packages-title">STANDART PAKET</span>
                    <div className="packages-list1">
                      <span>Online Teklif Alma</span>
                      <span>Online Poliçe Yapılandırma</span>
                      <span>Online Zeyil</span>
                      <span>Otomatik Yenileme</span>
                      <span>Yardım / Destek Modülü</span>
                      <span>Şube Modülü</span>
                      <span>Sorgulama Servis Modülü</span>
                      <span>Günlük Yedekleme Hizmeti</span>
                      <span>2FA Güvenli Giriş İmkanı</span>
                      <span>İnsan Kaynakları Modülü</span>
                      <span>Hedef Pazarlama Modülü</span>
                      <span>Duyuru Yönetim Modülü</span>
                      <span>Portföy Yönetim Modülü</span>
                    </div>
                  </div>
                  <div className="btn">
                    <Form.Item>
                      <Button
                        className="packagebtn"
                        type="#C00D13"
                        htmlType="submit"
                        onClick={() => handleButtonClick()}
                      >
                        Hızlı teklif alın
                      </Button>
                    </Form.Item>
                  </div>
                </div>
                <div className="packages-radius2">
                  <div className="packages-img">
                    <img alt="rocket" src="img/vector/Rocket.png"></img>
                  </div>
                  <div
                    style={{
                      background: "#F4D5D0",
                    }}
                    className="package-oge2"
                  >
                    <span className="packages-title">PROFESYONEL PAKET</span>
                    <div className="packages-list2">
                      <span>Online Teklif Alma</span>
                      <span>Online Poliçe Yapılandırma</span>
                      <span>Online Zeyil</span>
                      <span>Otomatik Yenileme</span>
                      <span>Yardım / Destek Modülü</span>
                      <span>Şube Modülü</span>
                      <span>Sorgulama Servis Modülü</span>
                      <span>Günlük Yedekleme Hizmeti</span>
                      <span>2FA Güvenli Giriş İmkanı</span>
                      <span>İnsan Kaynakları Modülü</span>
                      <span>Hedef Pazarlama Modülü</span>
                      <span>Duyuru Yönetim Modülü</span>
                      <span>Portföy Yönetim Modülü</span>
                      <span>Müşteri Memnuniyeti Yönetimi</span>
                      <span>Çapraz Satış</span>
                      <span>Otomatik Poliçe Aktarma</span>
                      <span>Online Kasko Değeri Sorgulama</span>
                      <span>Online Teklif ve Poliçe Entegrasyonu</span>
                    </div>
                  </div>
                  <div style={{ width: "338px" }} className="btn">
                    <Form.Item>
                      <Button
                        className="packagebtn"
                        type="#C00D13"
                        htmlType="submit"
                        onClick={() => handleButtonClick()}
                      >
                        Hızlı teklif alın
                      </Button>
                    </Form.Item>
                  </div>
                  {isPopup && <GetOffer onClose={closePopup} />}
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default Package;
