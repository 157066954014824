import axios from "axios";
import { Constants } from "./constants";
import { Subject } from "rxjs";
import getSession from "../auth/getSession";
import query from "query-string";
import { getSource, getToken } from "./common";
import getSessionSource from "../auth/getSessionSource";

class Httpio {
  static API = new Httpio(Constants.API, Constants.API);
  static CONTENT = new Httpio(Constants.CONTENT, Constants.CONTENT);
  static Payment = new Httpio(Constants.Payment_API, Constants.Payment_API);
  static Print = new Httpio(Constants.PRINT_API, Constants.PRINT_API);
  static ACENTE365 = new Httpio(Constants.ACENTE365, Constants.ACENTE365);

  /** @type {Subject} */
  static unAuthorizedExceptions = new Subject();

  Headers = {
    "Access-Control-Allow-Origin": "*",
    Authorization: "",
  };

  baseUrl = "";
  proxyPath = "";

  // #isSessionSet = false;

  /**
   *
   * @param {*} baseUrl For server side
   * @param {*} proxyPath For client side
   */
  constructor(baseUrl, proxyPath) {
    this.baseUrl = baseUrl;
    this.proxyPath = proxyPath;
    this.Headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
  }

  /**
   *
   * @param {string} path
   * @param {*} body
   * @returns {Promise}
   */
  post(path, body = {}, isJson, header, responseType, ctx) {
    return this.request({
      path,
      method: "POST",
      body,
      isJson,
      header,
      responseType,
      ctx,
    });
  }

  /**
   *
   * @param {string} path
   * @param {*} body
   * @returns {Promise}
   */

  put(path, body = {}) {
    return this.request({ path, method: "PUT", body });
  }
  /**
   *
   * @param {string} path
   * @param {*} body
   * @returns {Promise}
   */
  delete(path, body = {}) {
    return this.request({ path, method: "DELETE", body });
  }

  /**
   *
   * @param {string} path
   * @param {*} params
   * @returns {Promise}
   */
  get(path, params = {}, header, isJson) {
    return this.request({ path, params, isJson, header });
  }

  async request({
    path,
    method = "GET",
    params,
    body = {},
    isJson,
    header = {},
    responseType,
    ctx,
  }) {
    const token =
      ctx && typeof window === "undefined"
        ? getSession(ctx).token
        : typeof window !== "undefined"
        ? getSession()?.token
        : getToken();

    const source =
      ctx && typeof window === "undefined"
        ? getSessionSource(ctx).source
        : typeof window !== "undefined"
        ? getSessionSource()?.source
        : getSource();

    // header ve body için  AccessToken ve Source ayarları

    let generelRequestData = {};
    if (
      path === "/account/yetki" ||
      path === "/musteri/gecici-kod" ||
      path === "/internal/AMLKontrol" ||
      path === "/musteri/gecici-onay" ||
      path === "/musteri/uyeol" ||
      path === "/account/sifremiunuttum"
    ) {
      generelRequestData = {
        AccessToken: Constants.AccessToken,
        Source: Constants.Source,
      };
    } else {
      if (token != null) {
        generelRequestData = {
          AccessToken: `${this.basePath()}${path}`.includes(Constants.CONTENT)
            ? Constants.AccessToken
            : token,
          Source: `${this.basePath()}${path}`.includes(Constants.CONTENT)
            ? Constants.Source
            : source,
        };
      } else {
        generelRequestData = {
          AccessToken: `${this.basePath()}${path}`.includes(Constants.CONTENT)
            ? Constants.AccessToken
            : Constants.AccessToken,
          Source: `${this.basePath()}${path}`.includes(Constants.CONTENT)
            ? Constants.Source
            : Constants.Source,
        };
      }
    }

    //body
    const newBody = {
      FromPlace: 16,
      ...body,
      ...generelRequestData,
    };

    try {
      return await axios({
        url: `${this.basePath()}${path}`,
        responseType,
        headers: !isJson // json göre header ayarlıyor
          ? {
              SiteId: Constants.SiteId,
              Authorization: generelRequestData.AccessToken,
              Source: generelRequestData.Source,
              ...this.Headers,
              ...header,
            }
          : {
              SiteId: Constants.SiteId,
              Authorization: generelRequestData.AccessToken,
              Source: generelRequestData.Source,
              ...this.Headers,
              "Content-Type": "application/json",
              ...header,
            },
        method,
        params,
        timeout: 180000,
        data: isJson ? newBody : query.stringify(newBody),
      }).then((res) => {
        return res.data;
      });
    } catch (error) {
      // const _error = ErrorHandler.handle(error);
      // if (_error.isUnauthorizedError()) {
      //   Httpio.unAuthorizedExceptions.next(null);
      // }
      // throw _error;

      return error?.response?.data;
    }
  }

  /**
   * Returns baseUrl if server side, else proxyPath
   * @returns {string}
   */
  basePath() {
    // Checks is server side or client side
    if (typeof window === "undefined") {
      return this.baseUrl;
    } else {
      return this.proxyPath;
    }
  }
}

export default Httpio;
