import React from "react";

const Istaticks = () => {
  return (
    <div className="Staticks">
      <div className="static-container">
        <div className="statick-title">
          <span className="statick-titles">İstatistikler</span>
          <span>
            Sizin için hazırlayacağımız özel sunumla daha fazla bilgiye sahip
            olabilirsiniz
          </span>
        </div>
        <div className="statick-box">
          <div className="box">
            <img alt="icon" className="icon-1" src="img/iconn/1.png"></img>
            <div className="boxx">
              <h1>7 Milyon</h1>
              <span className="alt">Teklif</span>
            </div>
          </div>
          <div className="box">
            <img alt="icon" className="icon-2" src="img/iconn/2.png"></img>
            <div className="boxx">
              <h1>4 Milyon</h1>
              <span className="alt">Poliçe</span>
            </div>
          </div>
          <div className="box">
            <img alt="icon" className="icon-3" src="/img/iconn/3.png"></img>
            <div className="boxx">
              <h1>220+</h1>
              <span className="alt">Sigorta Acentesi</span>
            </div>
          </div>
          <div className="box">
            <div className="boxx">
              <img alt="icon" className="icon-4" src="img/iconn/4.png"></img>
              <h1>38</h1>
              <span className="alt">Sigorta Şirketi</span>
            </div>
          </div>
          <div className="box">
            <img alt="icon" className="icon-5" src="img/iconn/5.png"></img>
            <div className="boxx">
              <h1>14</h1>
              <span className="alt">Branş</span>
            </div>
          </div>
          <div className="box">
            <img alt="icon" className="icon-6" src="img/iconn/6.png"></img>
            <div className="boxx">
              <h1>3</h1>
              <div>
                <span className="alt">Sigorta Brokeri</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Istaticks;
