import React from "react";
import BreadCrump from "../component/BreadCrump/breadcrump";
import Contacts from "../component/Contact/contacts";
const Contact = () => {
  return (
    <>
      <div className="contact">
        <head>
          <title>İletişim</title>
          <meta charSet="UTF-8" />
          <meta name="description" content="İletişim" />
        </head>
        <div className="package-img">
          <img
            alt="banner"
            className="banner"
            src="img/banner/paket-banner.png"
          ></img>
        </div>
        <BreadCrump  activeClassName={true} pageTitle="Anasayfa" pageTitle2="İletişim" />
        <Contacts />
      </div>
    </>
  );
};

export default Contact;
