export const Constants = {
  API: "https://gateway.acente365.com/api",
  Payment_API: "https://gateway.acente365.com/payment",
  PRINT_API: "https://gateway.acente365.com/print",
  CONTENT: "https://cdnapi.megacode.com.tr/api",
  ACENTE365: "https://gateway.acente365.com/api",
  AccessToken:
    "93F94E788647DC826E9EE3673768F314180FBD9EDCDBBF961AED22EBC2A27321",
  Source: "MegaCode",
  SiteId: "27",
};
