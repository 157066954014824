import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";
import "swiper/css/autoplay";

const BusinessPartners = () => {
  return (
    <div className="business">
      <div className="business-headerr">
        <img
          alt="businessImg"
          className="business-img"
          src="img/vector/Vector.png"
        ></img>
        <div className="business-header">
          <span className="business-header-title">İş Ortaklarımız</span>
          <span className="business-header-description">
            Acente365 sistemlerinin alt yapısında çözüm ortağı olduğumuz
            firmalar
          </span>
        </div>
      </div>
      <div className="business-card-container">
        <Swiper
          className="swiperslider"
          spaceBetween={20}
          centeredSlides={true}
          slidesPerView={4.5}
          autoplay={{
            delay: 5000,
          }}
          modules={[Autoplay]}
          loop={true}
          speed={6000}
          breakpoints={{
            767: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            844: {
              slidesPerView: 2,
            },
            1200: {
              slidesPerView: 4,
            },
          }}
        >
          <SwiperSlide className="sliderswiperbusiness">
            <div className="business-card">
              <img
                className="sliderSwiperimg"
                src="img/firms/TURKCELL_YATAY_ERKEK_LOGO1.svg"
                alt="Turkcell"
              ></img>
            </div>
          </SwiperSlide>
          <SwiperSlide className="sliderswiperbusiness">
            <div className="business-card">
              <img
                src="img/firms/azure.svg"
                className="sliderSwiperimg"
                alt="Microsoft Azure"
              ></img>
            </div>
          </SwiperSlide>
          <SwiperSlide className="sliderswiperbusiness">
            <div className="business-card">
              <img
                src="img/firms/cisco.svg"
                className="sliderSwiperimg"
                alt="Cısco"
              ></img>
            </div>
          </SwiperSlide>
          <SwiperSlide className="sliderswiperbusiness">
            <div className="business-card">
              <img
                src="img/firms/citrix-1.svg"
                className="sliderSwiperimg"
                alt="Citrix"
              ></img>
            </div>
          </SwiperSlide>
          <SwiperSlide className="sliderswiperbusiness">
            <div className="business-card">
              <img
                src="img/firms/ceyber.svg"
                className="sliderSwiperimg"
                alt="Ceyber"
              ></img>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default BusinessPartners;
