import React from "react";

const Acente = () => {
  return (
    <div className="acente">
      <div className="acentepage">
        <div className="acente-title">
          <span className="acente365">
            Acente365
            <span className="why"> Nedir</span>
          </span>
          <span className="subtitle">
            Kısaca, sigorta hızlı yazılımları sunar. Ötesinde,
          </span>
        </div>

        <div className="acentedetail">
          <div className="acenteimg">
            <img
              alt="Acente365MobilUygulama"
              src="img/vector/solphone.png"
            ></img>
          </div>
          <div className="acentesubtitle">
            <p>
              Acente ve brokerlerin tüm operasyonlarını tek sistem üzerinden
              yönetebilmenizi sağlayan inovatif bir çözüm ortağıdır. Sistem
              sayesinde, müşteri takibinizi, teklifleri, poliçeleri,
              yenilemeleri, çapraz satış fırsatlarını ve tahsilat takibi gibi
              birçok alanda kolayca hizmet alırsınız.
            </p>
            <br />
            <p>
              Bulut tabanlı sistem, güçlü alt yapısı ile size avantaj yaratır.
              Uluslararası güvenlik standartlarına ve tüm yasal mevzuatlara
              uygun şekilde hazırlanan yazılımı sayesinde işinizi kolaylaştırır.
              Acente365 alt yapısında 14 branşta 38 farklı sigorta şirketinden
              tekliflerinizi alabilir, karşılaştırabilir ve
              poliçeleştirebilirsiniz.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Acente;
