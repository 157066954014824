import nookies from "nookies"


export default function getSession(ctx = {}) {

    try {
        const cookies = nookies.get(ctx)
        const token = cookies.token
        const decodedToken = JSON.parse(token);
        return decodedToken
    } catch (e) {
        return null;
    }
}
export async function getSessionAsync(ctx = {}) {
    return getSession(ctx)
}
