import React, { useState } from "react";

const CustomCollapse = ({ title, desc }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="collapse">
      <div className="collapse-container">
        <div className="collapse-header" onClick={toggleClick}>
          <div className="collapce">
            <span className="collapse-title">{title}</span>
            <div style={{ padding: "10px" }}>
              <img
                src={
                  isOpen
                    ? "/img/icon/direction-down01.png"
                    : "/img/icon/direction-down.png"
                }
                alt={isOpen ? "Open" : "Closed"}
              />
            </div>
          </div>
          <div>{isOpen && <div className="collapse-content">{desc}</div>}</div>
        </div>
      </div>
    </div>
  );
};

export default CustomCollapse;
