export const footerMenuData = [
  {
    title: "Kurumsal",
    links: [
      { name: "Acente365", url: "/Kurumsal?id=1" },
      { name: "Kullanıcı Sözleşmesi", url: "/Kurumsal?id=373" },
      { name: "Gizlilik Sözleşmesi", url: "/Kurumsal?id=373" },
      { name: "Açık Rıza Metni", url: "/Kurumsal?id=376" },
      { name: "Aydınlatma Metni", url: "/Kurumsal?id=372" },
    ],
  },
  {
    title: "Acente365",
    links: [
      { name: "Sertifikalar", url: "/" },
      {
        name: "Acente365 App Store",
        url: "https://apps.apple.com/tr/app/acente365/id1589496090?l=tr",
      },
      {
        name: "Acente365 Google Play",
        url: "https://play.google.com/store/apps/details?id=com.megacode.acente365mobile",
      },
      { name: "Bizden Haberler", url: "/blog" },
      { name: "Sıkça Sorulan Sorular", url: "/sikca-sorulan-sorular" },
    ],
  },
  {
    title: "Paketler",
    links: [
      { name: "Temel Paket", url: "/paketlerimiz" },
      { name: "Standart Paket", url: "/paketlerimiz" },
      { name: "Profesyonel Paket", url: "/paketlerimiz" },
    ],
  },
  {
    title: "İletişim",
    links: [
      { name: "İletişim Formu", url: "/iletisim" },
      { name: "info@acente365.com.tr", url: "mailto:info@acente365.com.tr" },
      { name: "0850 346 2201", url: "tel:08503462201" },
      {name: "", url: "", social: [
        {
          href: "https://www.instagram.com/acente365.official",
          src: "/img/socailMedia/instagram.svg",
          alt: "Instagram"
        },
        {
          href: "https://www.linkedin.com/company/megacode/mycompany/",
          src: "/img/socailMedia/linkedin.svg",
          alt: "Linkedin"
        },
        {
          href: "https://www.facebook.com/megacodetech/",
          src: "/img/socailMedia/fb.svg",
          alt: "Facebook"
        },
        {
          href: "https://twitter.com/megacodeyazilim",
          src: "/img/socailMedia/x-platform-solid.svg",
          alt: "Twitter"
        }
      ]}
    ],
  },
];
